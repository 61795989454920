import { yup } from '../../utils';
import { SLOT_SCHEMA } from '../../api/schemas';
import { useCallback, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/24/outline';
import { Td, TdArrivalTime, TdSlotNumber } from './shared';
import { Button } from '../button';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useUpdateSlotStatus } from '../../api/actions/update-slot-status';
import { EnumListBox } from '../enum-list-box';
import { EmptySlotAddAppointment } from './empty-slot-add-appointment';
import { useEnum } from '../../api/store/enums';
import { Label } from '../label';
import { EMPTY_OBJECT } from '../../constants';
import { useGenericErrorsToast } from '../toast';
import { useIsHistoric } from '../../hooks/use-is-historic';

export function EmptySlotRow({ slot, index }) {
  const [mode, setMode] = useState(0);

  switch (mode) {
    case 1:
      return <EmptySlotAddAppointment index={index} slot={slot} setMode={setMode} />;
    case 2:
      return <EmptySlotRowChangeAvailability index={index} slot={slot} setMode={setMode} />;
    default:
      return slot.status === 'Unavailable' ? (
        <EmptySlotUnavailable index={index} slot={slot} setMode={setMode} />
      ) : (
        <EmptySlotAvailable index={index} slot={slot} setMode={setMode} />
      );
  }
}

EmptySlotRow.propTypes = {
  index: yup.number().required().pt(),
  slot: SLOT_SCHEMA.required().pt(),
};

function EmptySlotAvailable({ index, slot, setMode }) {
  const isHistory = useIsHistoric(slot.arrivalTimeTimestamp);

  const cellProps = !isHistory
    ? {
        onClickHead: () => setMode(2),
        onClick: () => setMode(1),
      }
    : EMPTY_OBJECT;

  return (
    <tr className="group">
      <TdSlotNumber index={index} {...cellProps} />
      <TdArrivalTime slot={slot} {...cellProps} />
      <Td colSpan={10} {...cellProps}></Td>
      <Td
        className="text-center transition opacity-0 group-has-[.section2:hover]:opacity-100"
        {...cellProps}
      >
        <div className="flex justify-center items-center">
          <Button variant="plain" label="ADD" className={isHistory ? 'invisible' : null} />
        </div>
      </Td>
    </tr>
  );
}

EmptySlotAvailable.propTypes = {
  ...EmptySlotRow.propTypes,
  setMode: yup.mixed().callback().pt(),
};

const UPDATE_SLOT_AVAILABILITY_FORM_SCHEMA = yup.object().shape({
  status: yup.string().oneOf(['Available', 'Unavailable']).required(),
  reason: yup.string().when('status', {
    is: 'Unavailable',
    then: (schema) => schema.required(),
    otherwise: (schema) => schema.optional(),
  }),
});

const CALENDAR_SLOT_STATUS_TO_OMIT = ['Appointment'];

function EmptySlotRowChangeAvailability({ index, slot, setMode }) {
  const updateSlotAvailabilityForm = useForm({
    resolver: yupResolver(UPDATE_SLOT_AVAILABILITY_FORM_SCHEMA),
    mode: 'onTouched',
    defaultValues: {
      status: slot.status,
      reason: slot.statusReason,
    },
  });
  const genericErrorsToast = useGenericErrorsToast();

  const { isSubmitting, isValid, isLoading } = updateSlotAvailabilityForm.formState;
  const disabled = !isValid || isSubmitting || isLoading;

  const status = updateSlotAvailabilityForm.watch('status');
  const reasonDisabled = status !== 'Unavailable';
  updateSlotAvailabilityForm.watch(({ status }, { name, type }) => {
    if (name === 'status' && type === 'change' && status === 'Available') {
      updateSlotAvailabilityForm.setValue('reason', 'None');
    }
  });

  const updateSlotStatus = useUpdateSlotStatus();
  const onSubmit = useCallback(
    async (values) => {
      try {
        await updateSlotStatus.execute({
          calendarSlotId: slot.id,
          status: values.status,
          reason: values.reason,
        });
        setMode(0);
      } catch (error) {
        genericErrorsToast('Failed to update slot availability', error);
      }
    },
    [genericErrorsToast, setMode, slot.id, updateSlotStatus],
  );

  return (
    <tr className="group">
      <TdSlotNumber index={index} className="bg-gray-50" />
      <TdArrivalTime slot={slot} className="bg-gray-50" />
      <Td className="bg-gray-50" colSpan={10}>
        <FormProvider {...updateSlotAvailabilityForm}>
          <form
            onSubmit={updateSlotAvailabilityForm.handleSubmit(onSubmit)}
            className="m-0 flex gap-x-2"
          >
            <div className="w-44">
              <EnumListBox
                name="CalendarSlotStatus"
                field="status"
                valuesToOmit={CALENDAR_SLOT_STATUS_TO_OMIT}
              />
            </div>
            <div className="w-56">
              <EnumListBox
                name="CalendarSlotStatusReason"
                field="reason"
                disabled={reasonDisabled}
              />
            </div>
            <Button label="Save" type="submit" className="min-w-16" disabled={disabled} />
          </form>
        </FormProvider>
      </Td>
      <Td className="bg-gray-50 pr-3">
        <div className="flex justify-end items-center">
          <Button
            icon={XMarkIcon}
            variant="plain"
            labelClassName="sr-only"
            onClick={() => setMode(0)}
          />
        </div>
      </Td>
    </tr>
  );
}

EmptySlotRowChangeAvailability.propTypes = EmptySlotAvailable.propTypes;

function EmptySlotUnavailable({ index, slot, setMode }) {
  const cellProps = { onClickHead: () => setMode(2) };
  const calendarSlotStatusEnum = useEnum('CalendarSlotStatus');
  const calendarSlotStatusReasonEnum = useEnum('CalendarSlotStatusReason');
  return (
    <tr className="group">
      <TdSlotNumber index={index} {...cellProps} className="bg-gray-300" />
      <TdArrivalTime slot={slot} {...cellProps} className="bg-gray-300" />
      <Td {...cellProps} className="bg-gray-300">
        <Label label={calendarSlotStatusEnum[slot.status] ?? ''} className="shaded-black" />
      </Td>
      <Td {...cellProps} className="bg-gray-300" colSpan={10}>
        {calendarSlotStatusReasonEnum[slot.statusReason] ?? ''}
      </Td>
    </tr>
  );
}

EmptySlotUnavailable.propTypes = EmptySlotAvailable.propTypes;
