{
    "common": {
        "morning": "Morning",
        "afternoon": "Afternoon",
        "n/a": "N/A",
        "enums": {
            "CalendarSlotStatus": {
                "Available": "Available",
                "Appointment": "Appointment",
                "Unavailable": "Unavailable"
            },
            "CalendarSlotStatusReason": {
                "None": "None",
                "PractitionerRules": "Dr preferences",
                "PractitionerUnavailable": "Dr unavailable",
                "ContractList": "Contract list",
                "StaffUnavailable": "Staff unavailable",
                "Other": "Other"
            },
            "ReferralSource": {
                "Contract": "Contract",
                "Specialist": "Specialist",
                "Recall": "Recall",
                "GP": "GP"
            },
            "ListType": {
                "Standard": "Standard",
                "Propofol": "Propofol"
            },
            "AppointmentStatus": {
                "Proposed": "Proposed",
                "Pending": "Pending",
                "Booked": "Booked",
                "Arrived": "Arrived",
                "Fulfilled": "Fulfilled",
                "Cancelled": "Cancelled",
                "NoShow": "No show",
                "EnteredInError": "Entered in error",
                "CheckIn": "Check in",
                "Waitlist": "Waitlist"
            },
            "AppointmentCancellationTime": {
                "None": "None",
                "MoreThanThreeDaysBefore": "> 3 days before",
                "OneToThreeDaysBefore": "1 - 3 days before",
                "DayOf": "Day of",
                "OnAdmission": "On admission"
            },
            "AppointmentCancellationReason": {
                "None": "None",
                "Financial": "Financial",
                "DrCancel": "Dr Cancel",
                "OtherUnknown": "Other/Unknown",
                "MovedToDifferentList": "Moved to different list",
                "NoPrep": "No Preparation",
                "BadTiming": "Bad Timing",
                "HadElsewhere": "Had Elsewhere",
                "COVID": "COVID",
                "Family": "Family",
                "Work": "Work",
                "UnwellUnfit": "Unwell/Unfit",
                "DNA": "Did Not Attend"
            },
            "RuleType": {
                "OnlyAllow": "Only allow",
                "DoNotAllow": "Do no allow",
                "MinimumLimit": "Minimum",
                "MaximumLimit": "Maximum",
                "MaximumConsecutiveLimit": "Maximum Consecutive"
            }
        }
    }
}
